import { newAxiosIns } from '@/libs/axios'

const URL = '/komchat-api/api/v1/broadcast'

export function apiGetBroadcast(search, cursor, limit) {
  return newAxiosIns.get(`${URL}?cursor=${cursor}&limit=${limit}&search=${search}`)
}

export function apiSendBroadcast({ param }) {
  return newAxiosIns.post(`${URL}/send-broadcast`, param)
}

export function apiSendPreview({ param }) {
  return newAxiosIns.post(`${URL}/send-preview`, param)
}

export function apiActionBroadcast({ param }) {
  return newAxiosIns.post(`${URL}/${param.id}/${param.action}`)
}

export function apiSaveTempImage({ formData }) {
  return newAxiosIns.post('/komchat-api/api/v1/media/upload/img-temp', formData)
}

export function apiSpintax({ param }) {
  return newAxiosIns.post('/komchat-api/api/v1/ai/generate-spintax-text', param)
}

// for brands

export function apiListBroadcastForBrand(limit, offset, keyword) {
  const params = {
    limit,
    offset,
    keyword,
  }
  return newAxiosIns.get('/komchat-api/api/v1/kfb/broadcast', { params })
}

export function apiRequestBroadcastForBrand({ params }) {
  return newAxiosIns.post('/komchat-api/api/v1/kfb/request-broadcast', params)
}

export function apiSaveTempImageForBrand({ formData }) {
  return newAxiosIns.post('/komchat-api/api/v1/kfb/media/upload/img-temp', formData)
}

export function apiSendBroadcastForBrand(id) {
  const params = {
    id,
  }
  return newAxiosIns.post('/komchat-api/api/v1/kfb/send-broadcast', params)
}

export function apiHistoryBroadcastForBrand(limit, offset) {
  const params = {
    limit,
    offset,
  }
  return newAxiosIns.get('/komchat-api/api/v1/kfb/history-broadcast', { params })
}

export function apiInfoBroadcastForBrand() {
  return newAxiosIns.get('/komchat-api/api/v1/kfb/info-broadcast')
}
